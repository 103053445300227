import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { useSelector } from "react-redux"
import LoginForm from "../components/loginForm"
import Header from "../components/header"
import Grid from "@material-ui/core/Grid"
import { FontFamily } from "../components/styles/constants"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Link } from "gatsby"

const Index = () => {
  const { email, messages, allStudentsList, educatorsList } = useSelector(
    state => ({
      ...state.mainReducer,
      ...state.mainReducer.user.user,
    })
  )
  return (
    <>
      {email ? (
        <Layout>
          <CustomDiv>
            <Grid container spacing={2}>
              <Grid item >
                <Link to="/messages">
                  <FlatCard>
                    <CardContent>
                      <h1>New Messages</h1>
                      <h2>{messages && messages.length}</h2>
                    </CardContent>
                  </FlatCard>
                </Link>
              </Grid>

              <Grid item >
                <Link to="/studentsList">
                  <FlatCard>
                    <CardContent>
                      <h1>Number of Students</h1>
                      <h2>{allStudentsList && allStudentsList.length}</h2>
                    </CardContent>
                  </FlatCard>
                </Link>
              </Grid>
              <Grid item >
                <Link to="/schools">
                  <FlatCard>
                    <CardContent>
                      <h1>Number of Schools</h1>
                      <h2>
                        {educatorsList && educatorsList.filter(edu => edu.admin == !true).length}
                      </h2>
                    </CardContent>
                  </FlatCard>
                </Link>
              </Grid>
            </Grid>
          </CustomDiv>
        </Layout>
      ) : (
        <>
          <Header />
          <LoginForm />
        </>
      )}
    </>
  )
}
export default Index

//styled-components
const CustomDiv = styled.div`
  #from,
  #date {
    font-size: 12px;
    min-width: 100px;
    max-width: 100px;
  }
  #delete {
    max-width: 10px;
  }
  #message {
    min-width: 100px;
  }
  margin: 10px 10px 10px 10px;
  // height: 100vh;
  // border: 1px solid grey;
  span {
    // margin: 30px;
  }
  .MuiCircularProgress-root {
    margin: auto 50%;
    color: black;
  }
`

const FlatCard = styled(Card)`
  // border: 1px solid black;
  // box-shadow:none;
  h1 {
    color: #323232;
    font-size: 24px;
  }
  h2 {
    color: #323232;
    font-size: 36px;
  }
  h1,
  h2 {
    font-family: ${FontFamily};
  }
  border-radius: 5px;
  background-color: #fbfcfc;
  padding: 0px 5% 0px 5%;
`
